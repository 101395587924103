import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';

import screen1 from 'img/help/shopify/shopify_marketplace.png';
import screen2 from 'img/help/shopify/shopify_oauth_consent_screen2.png';
import screen3 from 'img/help/shopify/shopify_website_consent_screen.png';
import screen4 from 'img/help/shopify/shopify_installed.png';
import logo from 'img/help/integrations/shopify.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Shopify?</SubTitle>
      <p>
        <strong>Shopify</strong> is one of the biggest e-commerce solutions in the world. You can
        add a LiveSession tracking script to your Shopify-based online store and see how the users
        behave on the website.
      </p>
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        This integration install LiveSession on your shop with just few one-click steps. As a
        result, your shop visitors will be tracked by LiveSession.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>
        To install integration, visit our marketplace. And then click &quot;Install now&quot;. After
        that you&quot;ll be redirected to Shopify App Store.
      </span>{' '}
      <Image src={screen1} lazy alt="Google Analytics intergration" title="Opening user profile" />
      <p>
        After adding LiveSession you&quot;ll be redirected to Shopify&apos;s OAuth Consent Screen.
      </p>
      <Image
        src={screen2}
        lazy
        alt="Google Analytics intergration"
        title="Find event on actions list and expand details"
      />
      <p>
        Or if you have multiple websites in your LiveSession account, first you&quot;ll need to
        choose from which website tracking code should be applied. After that you&quot;ll be
        redirected to Shopify&apos;s Consent Screen.
      </p>
      <Image
        src={screen3}
        lazy
        alt="Google Analytics intergration"
        title="Copy-paste link to your web browser"
      />
      <p>
        After app installation confirmation from previous step you should be redirected to
        LiveSession dashboard with already installed integration.
      </p>
      <Image
        src={screen4}
        lazy
        alt="Google Analytics intergration"
        title="Copy-paste link to your web browser"
      />
      <p>After all, LiveSession should be installed on your shop.</p>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Shopify integration',
  metaDescription: 'Send LiveSession recordings from your Shopify store',
  canonical: '/help/shopify-integration/',
  logo,
};

const Wrapped = HelpArticle(Content);

export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
